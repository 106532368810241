$(function () {
    $('.hero__slider').slick({
        arrows: true,
        prevArrow: '<button type="button" class="material-icons hero__arrows hero__arrows--prev">chevron_left</button>',
        nextArrow: '<button type="button" class="material-icons hero__arrows hero__arrows--next">chevron_right</button>',
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        fade: true,
        cssEase: 'linear'
    })

    setLocationsSlick();

    // $('.activities-home-slider').slick({
    //     arrows: true,
    //     prevArrow: '<button type="button" class="button-slider button-slider--prev"><span class="material-icons button-slider__icon">chevron_left</span></button>',
    //     nextArrow: '<button type="button" class="button-slider button-slider--next"><span class="material-icons button-slider__icon">chevron_right</span></button>',
    //     centerMode: true,
    //     infinite: false,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     mobileFirst: true,
    //     centerPadding: '40px',
    //     responsive: [
    //         {
    //             breakpoint: 428,
    //             settings: {
    //                 slidesToShow: 1.4,
    //                 slidesToScroll: 1,
    //                 centerMode: false,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 centerMode: false,
    //             }
    //         },
    //         {
    //             breakpoint: 767,
    //             settings: {
    //                 slidesToShow: 2.5,
    //                 slidesToScroll: 1,
    //                 centerMode: false, 
    //             }
    //         },
    //         {
    //             breakpoint: 1170,
    //             settings: {
    //                 slidesToShow: 4,
    //                 slidesToScroll: 1,
    //                 centerMode: false, 
    //             }
    //         }
    //     ]
    // })

    $('.explore-slider, .explore-slider-mb').slick({
        arrows: false
    });

    $('.transfers-home-slider').slick({
        arrows: false,
        infinite: false,
        slidesToShow: 1.3,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.3
                }
            },
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    })

    $('.testimonials__slider').slick({
        arrows: true,
        prevArrow: '<button type="button" class="material-icons testimonials__arrows testimonials__arrows--prev">chevron_left</button>',
        nextArrow: '<button type="button" class="material-icons testimonials__arrows testimonials__arrows--next">chevron_right</button>'
    })

    $('.logos-oasis-slider').slick({
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });

    flatpickr('#activity_date', {
        dateFormat: 'd-m-Y',
        disableMobile: 'true',
        minDate: new Date().fp_incr(1)
    });

    flatpickr('#disc_date', {
        dateFormat: 'd-m-Y',
        disableMobile: 'true',
        minDate: new Date().fp_incr(1)
    });

    $('#activity_location').select2({
        placeholder: "Select a option",
        selectionCssClass: 'style-select2--global',
    });

    $('#transfer_destination').select2({
        placeholder: "Select a option",
        selectionCssClass: 'style-select2--global',
    });

    $('#disc_location').select2({
        placeholder: "Select a option",
        selectionCssClass: 'style-select2--global',
    });

    Livewire.hook('message.processed', () => {
        $('#transfer_destination').select2({
            placeholder: "Select a option",
            selectionCssClass: 'style-select2--global',
        });

        $('#disc_location').select2({
            placeholder: "Select a option",
            selectionCssClass: 'style-select2--global',
        });
    });
});

$('.gtm-banners').on('click', function(e){
    tagManager.gtmBanners(e.target.innerText);
});

$('.gtm-showBooking').on('click', function(e){
    tagManager.gtmShowBooking(e.target.innerText);
})

$(window).on('resize', function(){
    if($(window).width() < 767 && !$('.home-locations-slick').hasClass('slick-initialized')){
        setLocationsSlick();
    }
});

document.addEventListener('livewire:load', function () {
    const livewireComponent = document.getElementById('search-motor-component');
    const idComponent = livewireComponent.getAttribute('wire:id');
    const searchMotorComponent = Livewire.find(idComponent);

    $('#activity_location').on('change', function () {
        searchMotorComponent.set('activity_location', $(this).val());
    });

    $('#transfer_destination').on('change', function () {
        searchMotorComponent.set('transfer_destination', this.value)
    });

    $('#disc_location').on('change', function () {
        searchMotorComponent.set('disc_location', this.value)
    });

    var transfer_date = flatpickr('#transfer_date', {
        dateFormat: 'd-m-Y',
        mode: 'range',
        disableMobile: 'true',
        minDate: new Date().fp_incr(1),
        locale: {
            rangeSeparator: ' - '
        }
    });

    searchMotorComponent.on('updateFlatpickr', function (transfer_type, date) {
        var date_split = date.split(' - ');
        if (transfer_type == 'Redondo- Aeropuerto - Hotel - Aeropuerto') {
            var final_date = moment(date_split[0], 'DD-MM-Y').add(7, 'days').format('DD-MM-Y');
            transfer_date.set('mode', 'range');
            transfer_date.setDate(date_split[0] + ' - ' + final_date);
            searchMotorComponent.transfer_date = date_split[0] + ' - ' + final_date;
        }

        if (transfer_type == 'Desde el Aeropuerto') {
            transfer_date.set('mode', 'single');
            transfer_date.setDate(date_split[0]);
            searchMotorComponent.transfer_date = date_split[0];
        }

        if (transfer_type == 'Hacia al Aeropuerto') {
            transfer_date.set('mode', 'single');
            transfer_date.setDate(date_split[0]);
            searchMotorComponent.transfer_date = date_split[0];
        }
    });

    searchMotorComponent.on('tagManagerSearch', function(location, month, type){
        tagManager.gtmSearchMotor(location, month, type);
    });

});

$(document).on('submit', '#transfer-select', function (event) {
    event.preventDefault();
    const data = $(this).serialize(); 
    const button = event.target.querySelector('button');
    const service = JSON.parse(button.getAttribute('data-transfer'));
    const lang = window.location.pathname.split('/')[1];
    
    $.ajax({
        url: `/transfer-reservation`,
        method: "POST",
        data: data,
        success: function (data) {
            if (data.success) {
                tagManager.gtmAddToCart(service, lang);
                window.location.href = "/" + lang + (lang ==='es' ? '/carrito-compras' : '/checkout-cart');
            }
        },
        error: function (error) {
            set_errors(error.responseJSON);
        }
    });
});

const setLocationsSlick = () => {
    $('.home-locations-slick').slick({
        arrows: true,
        prevArrow: `<button type="button" class="button-slider button-slider--prev">
                        <span class="material-icons button-slider__icon">chevron_left</span>
                    </button>`,
        nextArrow: `<button type="button" class="button-slider button-slider--next">
                        <span class="material-icons button-slider__icon">chevron_right</span>
                    </button>`,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: "unslick"
            }
        ]
    });
}
